import React from "react"

const ArrowX = () => {
  return (
    <span className="c-icon__arrow">
      <span className="c-icon__arrow__origin c-icon__arrow__origin__x">
        <svg
          className="c-icon__arrow__def"
          width="16"
          height="15"
          viewBox="0 0 16 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className="c-icon__arrow-path"
            d="M1 7.0481H15"
            stroke="#1F140F"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            className="c-icon__arrow-path"
            d="M8.95386 13.0939L15 7.04781L8.95386 1"
            stroke="#1F140F"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </span>
      <span className="c-icon__arrow__dammy c-icon__arrow__dammy__x">
        <svg
          className="c-icon__arrow__def"
          width="16"
          height="15"
          viewBox="0 0 16 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className="c-icon__arrow-path"
            d="M1 7.0481H15"
            stroke="#1F140F"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            className="c-icon__arrow-path"
            d="M8.95386 13.0939L15 7.04781L8.95386 1"
            stroke="#1F140F"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </span>
    </span>
  )
}

export default ArrowX
