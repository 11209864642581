import { Link } from "gatsby"
import React from "react"
import ArrowX from "./ArrowX"

export default function NewsList({ news }) {
  const { title, publishedAt, slug, category } = news

  const linkUrl = `/news/${slug}`
  let type = category[0].toUpperCase()
  return (
    <ul className="p-common-news__body-list">
      <li
        className="js-hover p-common-news__body-item"
        data-hover-in-time="600"
        data-hover-out-time="650"
      >
        <Link className="c-link__main p-common-news__body-link" to={linkUrl}>
          <div className="p-common-news__body-item-block">
            <span className="p-common-news__body-item-block-time">
              {publishedAt}
            </span>
            <span className="p-common-news__body-item-block-type">{type}</span>
            <h2 className="p-common-news__body-item-block-ttl">{title}</h2>
          </div>
          <div className="p-common-news__body-item-icon">
            {/*<span className="p-common-news__body-item-icon-info">*/}
            {/*  PDF*/}
            {/*</span>*/}
            <ArrowX />
          </div>
        </Link>
      </li>
    </ul>
  )
}
