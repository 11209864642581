import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/common/Layout"
import Footer from "../components/common/Footer"
import Learn from "../components/common/Learn"
import Cursor from "../components/common/Cursor"
import Kv from "../components/section/top/Kv"
import Vision from "../components/section/top/Vision"
import Service from "../components/section/top/Service"
import Careers from "../components/section/top/Careers"
import News from "../components/section/top/News"

export default function Home({ data }) {
  return (
    <Layout>
      <div id="page">
        <div id="home" className="js-page">
          <main id="main">
            <div id="contents" className="contents">
              <Cursor />

              <Kv />
              <Vision />
              <Service />
              <Careers />
              <News props={data} />
              <Learn />
            </div>
          </main>
          <Footer />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query allContentfulNewsAndAllContentfulNews {
    allContentfulNews(sort: { fields: [publishedAt], order: DESC }) {
      edges {
        node {
          updatedAt(locale: "ja-JP", formatString: "YYYY.MM.DD")
          publishedAt(locale: "ja-JP", formatString: "YYYY.MM.DD")
          title
          category
          body {
            body
          }
          slug
        }
      }
    }
  }
`
