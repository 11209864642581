import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import ArrowX from "../../common/ArrowX"
import ArrowZ from "../../common/ArrowZ"
import Img from "../../common/Img"
import { Link } from "gatsby"

const Service = () => {
  return (
    <section className="p-top-service">
      <div className="p-top-service__wrapper">
        <div className="p-contents p-contents__service">
          <div className="p-contents__wrapper">
            <h2 className="c-ttl__en p-contents__en">SERVICE</h2>
            <div className="p-contents__block">
              <h3 className="c-ttl__min p-contents__ttl">
                私たちは飲食店のDXを通じて
                <br className="u-none__sp" />
                外食業界にイノベーションを起こす、
                <br className="u-none__sp" />
                全く新しい外食企業です。
              </h3>
              <p className="c-txt__main p-contents__txt">
                創業当初から飲食業を愛するメンバーが集まり、より良いお店をつくるために様々なトライ＆エラーを繰り返し前に進んできたCRISP。レストランの運営だけでなく、飲食に携わる私たちならではの発想で、飲食店の課題解決に特化したAPPやSaaSも自社開発してきました。いま、私たちが培ってきた知見や専門性は、サラダを通じて新しいレストラン体験を提供する「CRISP
                SALAD WORKS」に展開されています。
              </p>
            </div>
          </div>
          <Link
            className="js-hover p-contents__link"
            to="/service/"
            data-hover-in-time="600"
            data-hover-out-time="650"
          >
            <div className="c-link__main p-contents__link-wrapper">
              <span className="p-contents__link-span">
                <span className="c-link__main-inner">
                  CRISPがやっていること
                </span>
                <ArrowX />
              </span>
            </div>
          </Link>
        </div>
        <section className="p-contents p-contents__restaurant">
          <div className="js-scroll__dark p-contents__img">
            <Img
              src="/asset/img/top/restaurant.jpg"
              src2x="/asset/img/top/restaurant@2x.jpg"
              webpSrc="/asset/img/top/restaurant.jpg.webp"
              webpSrc2x="/asset/img/top/restaurant@2x.jpg.webp"
              alt="店舗の内装"
            />
          </div>
          <div className="p-contents__wrapper">
            <h2 className="c-ttl__en p-contents__en">RESTAURANT</h2>
            <div className="p-contents__block">
              <div className="p-contents__ttl">
                <svg
                  className="p-contents__ttl-svg"
                  viewBox="0 0 240 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.822 0C7.4712 0 4.69998 1.26367 2.58436 3.75619C0.870954 5.78947 0 8.13629 0 10.7333C0 13.6312 1.04198 16.1427 3.09743 18.1982C5.15921 20.2315 7.68023 21.2639 10.5971 21.2639C12.5322 21.2639 14.4008 20.7604 16.1586 19.7627L16.3993 19.6265V14.7587L15.617 15.4175C15.1514 15.8102 14.7017 16.1491 14.2868 16.4246C13.8783 16.6938 13.4792 16.9123 13.0865 17.0739C12.4277 17.3811 11.6011 17.5363 10.6351 17.5363C8.73804 17.5363 7.18299 16.8902 5.87498 15.56C4.56696 14.2298 3.93038 12.6367 3.93038 10.6921C3.93038 8.72221 4.56697 7.10382 5.87815 5.74513C7.17349 4.38644 8.72537 3.72451 10.6193 3.72451C12.3232 3.72451 14.0018 4.40861 15.6138 5.7578L16.3929 6.41022V1.62789L16.1554 1.49171C14.4515 0.500402 12.6558 0 10.822 0Z"
                    fill="#1F140F"
                  />
                  <path
                    d="M30.2965 10.4703C31.1548 9.3903 31.5919 8.08861 31.5919 6.59691C31.5919 4.68398 30.8951 3.11943 29.5206 1.9476C28.2886 0.908789 26.2996 0.379883 23.6108 0.379883H19.6741V20.9091H23.5791V13.1687L28.9822 20.9091H33.7772L27.5696 12.3674C28.6655 12.0063 29.5808 11.3697 30.2965 10.4703ZM27.766 6.63808C27.766 7.81308 27.3796 9.37129 24.0447 9.37129H23.5823V4.10756H24.1587C27.3891 4.10756 27.766 5.55176 27.766 6.63808Z"
                    fill="#1F140F"
                  />
                  <path
                    d="M39.3164 0.379883H35.4114V20.9091H39.3164V0.379883Z"
                    fill="#1F140F"
                  />
                  <path
                    d="M50.7022 8.34532L48.7512 7.54721C47.5161 7.04047 46.9143 6.42922 46.9143 5.67862C46.9143 5.12754 47.1233 4.68098 47.5604 4.3136C48.0165 3.92088 48.5834 3.73085 49.2928 3.73085C49.8661 3.73085 50.3285 3.8417 50.6895 4.0729C50.9175 4.20591 51.2944 4.54796 51.798 5.42208L52.0387 5.84014L55.2533 3.93354L55.0189 3.52499C53.6634 1.1845 51.7473 0 49.3182 0C47.5319 0 46.0149 0.544742 44.8082 1.61206C43.5825 2.68887 42.9618 4.05073 42.9618 5.65645C42.9618 8.03494 44.4123 9.85286 47.2754 11.0627L49.1598 11.845C49.6222 12.0445 50.0244 12.2535 50.357 12.4721C50.6705 12.6779 50.927 12.8964 51.1234 13.1276C51.3071 13.3462 51.4433 13.5869 51.532 13.8466C51.6206 14.1126 51.665 14.4135 51.665 14.7429C51.665 15.5663 51.4116 16.2251 50.889 16.754C50.3696 17.2797 49.7267 17.5363 48.9318 17.5363C47.9183 17.5363 47.174 17.1847 46.6451 16.4531C46.4488 16.1997 46.1859 15.6202 46.0307 14.3026L45.9705 13.7801L42.0338 14.6479L42.1003 15.0849C42.3854 17.0137 43.1233 18.5371 44.292 19.6202C45.486 20.7097 47.0093 21.2608 48.8209 21.2608C50.7338 21.2608 52.3586 20.6178 53.6539 19.3478C54.9429 18.0842 55.5985 16.4689 55.5985 14.5402C55.5985 13.0928 55.1931 11.845 54.3919 10.8378C53.5969 9.85603 52.3586 9.01675 50.7022 8.34532Z"
                    fill="#1F140F"
                  />
                  <path
                    d="M68.6375 2.15371C67.9376 1.46328 67.1173 0.988218 66.1956 0.741184C65.3089 0.500484 64.0547 0.383301 62.3539 0.383301H58.5312V20.9125H62.4363V12.963H63.3294C65.4767 12.963 67.2155 12.3961 68.4981 11.275C69.7998 10.138 70.4617 8.58293 70.4617 6.65734C70.4649 4.85209 69.8505 3.33821 68.6375 2.15371ZM66.6612 6.61299C66.6612 7.75315 66.2875 9.2702 63.0665 9.2702H62.4395V4.05714H62.9399C66.2748 4.05714 66.6612 5.51718 66.6612 6.61299Z"
                    fill="#1F140F"
                  />
                  <path
                    d="M77.2077 15.3859C77.499 16.8871 78.1103 18.0747 79.0288 18.9235C80.0517 19.8578 81.3249 20.3139 82.9148 20.3139C84.5934 20.3139 85.9584 19.7786 87.0827 18.6733C88.1975 17.5807 88.7423 16.2315 88.7423 14.5466C88.7423 13.3177 88.4066 12.2726 87.7415 11.4333C87.0605 10.575 85.9521 9.83392 84.4477 9.22584L82.4936 8.42456C80.8847 7.76264 80.0676 6.83784 80.0676 5.67552C80.0676 4.8394 80.3969 4.1363 81.0494 3.58523C81.6733 3.04999 82.4651 2.77445 83.3962 2.77445C84.1531 2.77445 84.7897 2.93597 85.287 3.25585C85.6733 3.48071 86.0661 3.90194 86.4746 4.52902L88.0518 3.59473C86.8927 1.81798 85.3756 0.950195 83.4215 0.950195C81.8538 0.950195 80.5838 1.39992 79.5387 2.32472C78.5094 3.22734 78.0121 4.31683 78.0121 5.65651C78.0121 7.64545 79.2315 9.12449 81.743 10.1855L83.6306 10.9709C84.15 11.1926 84.5997 11.4301 84.9766 11.6772C85.3693 11.9337 85.6955 12.2156 85.9489 12.5165C86.2118 12.8268 86.4049 13.1752 86.5316 13.5458C86.652 13.91 86.7153 14.3122 86.7153 14.7429C86.7153 15.8134 86.3606 16.716 85.6638 17.4223C84.9639 18.1286 84.0803 18.4896 83.032 18.4896C81.7081 18.4896 80.682 17.9956 79.9852 17.0233C79.6558 16.5957 79.4151 15.9053 79.26 14.9361L77.2077 15.3859Z"
                    fill="#1F140F"
                  />
                  <path
                    d="M108.98 19.959H116.743V18.1316H110.985V1.33008H108.98V19.959Z"
                    fill="#1F140F"
                  />
                  <path
                    d="M127.207 0.766602L118.272 19.9593H120.404L122.586 15.2054H131.584L133.639 19.9593H135.828L127.207 0.766602ZM123.381 13.378L127.175 5.0802L130.795 13.378H123.381Z"
                    fill="#1F140F"
                  />
                  <path
                    d="M149.034 3.73099C148.094 2.86954 147.033 2.25196 145.88 1.89407C144.781 1.52036 143.264 1.3335 141.354 1.3335H137.722V19.9624H141.304C143.229 19.9624 144.727 19.7882 145.756 19.443C146.855 19.0978 147.913 18.477 148.917 17.5966C150.906 15.8388 151.913 13.5015 151.913 10.648C151.91 7.79756 150.941 5.46973 149.034 3.73099ZM147.546 16.1809C146.789 16.865 145.931 17.3717 144.993 17.6884C144.065 17.983 142.903 18.1318 141.532 18.1318H139.726V3.15775H141.532C144.163 3.15775 146.194 3.80067 147.571 5.07068C149.104 6.49588 149.88 8.3708 149.88 10.6448C149.88 12.9061 149.095 14.7684 147.546 16.1809Z"
                    fill="#1F140F"
                  />
                  <path
                    d="M196.142 3.81645C194.248 1.92252 191.946 0.962891 189.301 0.962891C186.618 0.962891 184.291 1.91619 182.381 3.79428C180.465 5.67237 179.492 7.94636 179.492 10.5561C179.492 13.5363 180.557 15.975 182.653 17.8056C184.566 19.4683 186.751 20.3139 189.149 20.3139C191.866 20.3139 194.216 19.3638 196.136 17.4952C198.042 15.6361 199.011 13.3399 199.011 10.6701C199.008 8.01603 198.042 5.71038 196.142 3.81645ZM194.723 16.2188C193.222 17.7264 191.36 18.4896 189.184 18.4896C187.173 18.4896 185.374 17.7422 183.844 16.2663C182.302 14.7873 181.516 12.8775 181.516 10.5909C181.516 8.40875 182.27 6.5465 183.762 5.06112C185.25 3.55675 187.1 2.79348 189.26 2.79348C191.429 2.79348 193.276 3.55675 194.748 5.06429C196.224 6.55916 196.975 8.43409 196.975 10.6416C196.978 12.8205 196.218 14.6955 194.723 16.2188Z"
                    fill="#1F140F"
                  />
                  <path
                    d="M206.755 11.7784L207.468 11.6263C208.671 11.3698 209.608 10.7966 210.34 9.88126C211.072 8.9628 211.43 7.88915 211.43 6.60014C211.43 4.95641 210.863 3.67057 209.694 2.6761C208.639 1.78297 206.856 1.33008 204.399 1.33008H201.412V19.959H203.417V11.9462H204.671L210.264 19.959H212.696L206.755 11.7784ZM203.414 10.3215V3.1575H204.94C208.709 3.1575 209.498 5.05143 209.498 6.63814C209.498 8.31988 208.687 10.3215 204.826 10.3215H203.414Z"
                    fill="#1F140F"
                  />
                  <path
                    d="M225.317 19.959H228.035L219.503 9.69123L227.851 1.33008H225.254L216.956 9.83375V1.33008H214.951V19.959H216.956V12.1362L218.077 11.0151L225.317 19.959Z"
                    fill="#1F140F"
                  />
                  <path
                    d="M228.465 15.3859C228.757 16.8871 229.368 18.0747 230.286 18.9235C231.309 19.8578 232.583 20.3139 234.173 20.3139C235.851 20.3139 237.216 19.7786 238.34 18.6733C239.455 17.5807 240 16.2315 240 14.5466C240 13.3177 239.664 12.2726 238.999 11.4333C238.318 10.575 237.21 9.83392 235.705 9.22584L233.751 8.42456C232.142 7.76264 231.325 6.83784 231.325 5.67552C231.325 4.8394 231.655 4.1363 232.307 3.58523C232.931 3.04999 233.723 2.77445 234.654 2.77445C235.411 2.77445 236.047 2.93597 236.545 3.25585C236.931 3.48071 237.324 3.90194 237.732 4.52902L239.31 3.59473C238.15 1.81798 236.633 0.950195 234.679 0.950195C233.112 0.950195 231.842 1.39992 230.796 2.32472C229.767 3.22734 229.27 4.31683 229.27 5.65651C229.27 7.64545 230.489 9.12449 233.001 10.1855L234.888 10.9709C235.408 11.1926 235.857 11.4301 236.234 11.6772C236.627 11.9337 236.953 12.2156 237.207 12.5165C237.469 12.8268 237.663 13.1752 237.789 13.5458C237.91 13.91 237.973 14.3122 237.973 14.7429C237.973 15.8134 237.618 16.716 236.922 17.4223C236.222 18.1286 235.338 18.4896 234.29 18.4896C232.966 18.4896 231.94 17.9956 231.243 17.0233C230.914 16.5957 230.673 15.9053 230.518 14.9361L228.465 15.3859Z"
                    fill="#1F140F"
                  />
                  <path
                    d="M172.202 21.1562L180.465 1.33008H178.289L172.265 16.0381L168.921 8.22486L171.622 2.28021H164.518L166.935 8.21536L163.461 16.0381L157.437 1.33008H155.261L163.524 21.1562L167.872 10.5273L172.202 21.1562ZM168.902 4.02212L167.986 6.04273L167.122 4.02212H168.902Z"
                    fill="#1F140F"
                  />
                  <path
                    d="M98.5381 0.766602L89.6037 19.9593H91.7384L93.9205 15.2054L94.0282 14.9584C95.314 15.8579 96.8406 16.3456 98.4431 16.3456C100.03 16.3456 101.544 15.8674 102.823 14.9806L102.918 15.2023L104.974 19.9561H107.162L98.5381 0.766602ZM94.8073 13.169L98.5065 5.0802L102.044 13.1912C99.9285 14.8634 96.9134 14.857 94.8073 13.169Z"
                    fill="#1F140F"
                  />
                </svg>
              </div>
              <p className="c-txt__main p-contents__txt">
                CRISP SALAD
                WORKSは、美味しくて満足できるサラダを、一つひとつ手づくりで提供するカスタムサラダ専門のレストランです。
                <br className="u-none__sp" />
                オンラインでもオフラインでも、サラダを通じてお客様とちょっとした心の交流ができる、そんなレストラン体験を提供します。
              </p>
            </div>
            <a
              className="js-hover p-contents__link"
              href="https://www.crisp.co.jp/"
              data-hover-in-time="600"
              data-hover-out-time="650"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="c-link__main p-contents__link-wrapper">
                <span className="p-contents__link-span">
                  <span className="c-link__main-inner">CRISP SALAD WORKS</span>
                  <ArrowZ />
                </span>
              </div>
            </a>
          </div>
        </section>
      </div>
    </section>
  )
}

export default Service
