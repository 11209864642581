import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import ArrowX from "../../common/ArrowX"
import Img from "../../common/Img"
import { Link } from "gatsby"

const Vision = () => {
  return (
    <section className="p-top-vision">
      <div className="p-top-vision__wrapper">
        <div className="p-contents p-contents__vision">
          <div className="p-contents__wrapper">
            <h2 className="c-ttl__en p-contents__en">OUR MISSION</h2>
            <div className="p-contents__block">
              <h3 className="c-ttl__main p-contents__ttl">
                熱狂的な <br />
                ファンをつくる。
              </h3>
              <p className="c-txt__main p-contents__txt">
                日本の外食は、もっともっと進化できる。
                <br />
                私たちCRISPは、テクノロジー×人の力で飲食の持つ可能性を最大化しながら、外食の未来を面白くするような、新しいレストラン体験を創り続けています。
              </p>
            </div>
            <Link
              className="js-hover p-contents__link"
              to="/vision/"
              data-hover-in-time="600"
              data-hover-out-time="650"
            >
              <div className="c-link__main p-contents__link-wrapper">
                <span className="p-contents__link-span">
                  <span className="c-link__main-inner">CRISPが描く未来</span>
                  <ArrowX />
                </span>
              </div>
            </Link>
          </div>
        </div>
        <div className="js-scroll__dark p-top-vision__imgs">
          <div className="p-top-vision__imgs__left">
            <div className="p-top-vision__imgs__left-img">
              <Img
                src="/asset/img/top/vision_1.jpg"
                src2x="/asset/img/top/vision_1@2x.jpg"
                webpSrc="/asset/img/top/vision_1.jpg.webp"
                webpSrc2x="/asset/img/top/vision_1@2x.jpg.webp"
                alt="店内で働くスタッフ"
              />
            </div>
          </div>
          <div className="p-top-vision__imgs__right">
            <div className="p-top-vision__imgs__right-img__top">
              <Img
                src="/asset/img/top/vision_2.jpg"
                src2x="/asset/img/top/vision_2@2x.jpg"
                webpSrc="/asset/img/top/vision_2.jpg.webp"
                webpSrc2x="/asset/img/top/vision_2@2x.jpg.webp"
                alt="デリバリー中のスタッフ"
              />
            </div>
            <div className="p-top-vision__imgs__right-img__bottom">
              <Img
                src="/asset/img/top/vision_3.jpg"
                src2x="/asset/img/top/vision_3@2x.jpg"
                webpSrc="/asset/img/top/vision_3.jpg.webp"
                webpSrc2x="/asset/img/top/vision_3@2x.jpg.webp"
                alt="店内での注文"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Vision
