import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import ArrowX from "../../common/ArrowX"
import Img from "../../common/Img"
import { Link } from "gatsby"

const Careers = () => {
  return (
    <section className="p-top-careers">
      <div className="p-top-careers__wrapper">
        <div className="p-top-careers__header">
          <div className="p-top-careers__header-content">
            <span className="u-none__sp p-top-careers__header-content-sub">
              CHIEF TECHNOLOGY OFFICER
            </span>
            <span className="u-none__pc p-top-careers__header-content-sub">
              CTO
            </span>
            <span className="p-top-careers__header-content-main">
              HIROKI WADA
            </span>
          </div>
          <div className="js-scroll__dark p-top-careers__img">
            <Img
              src="/asset/img/common/careers.jpg"
              src2x="/asset/img/common/careers@2x.jpg"
              spSrc="/asset/img/common/sp/careers_kv.jpg"
              webpSrc="/asset/img/common/careers.jpg.webp"
              webpSrc2x="/asset/img/common/careers@2x.jpg.webp"
              // webpSpSrc="/asset/img/common/sp/careers_kv.jpg.webp"
              alt="HIROKI WADA"
            />
          </div>
        </div>
        <div className="p-contents p-contents__careers">
          <div className="p-contents__wrapper">
            <h2 className="c-ttl__en p-contents__en">CAREERS</h2>
            <div className="p-contents__block">
              <h3 className="c-ttl__main p-contents__ttl">
                CRISPだから、できる挑戦がある。
                <br className="u-none__sp" />
                外食を進化させるトップランナーへ。
              </h3>
              <p className="c-txt__main p-contents__txt">
                これからどんどん加速していく外食産業のDX。
                <br className="u-none__sp" />
                けれど今、それが本当の意味で実践できている企業はほとんどありません。DXを推し進め、新しいレストラン体験を創る。そのためなら、なんでも挑戦できるのがCRISPです。私たちの全員が、チャレンジャーであると同時に日本のトップランナーです。あなたの力で、私たちと一緒に新しい外食の未来をつくっていきましょう。
              </p>
            </div>
            <Link
              className="js-hover p-contents__link"
              to="/careers/"
              data-hover-in-time="600"
              data-hover-out-time="650"
            >
              <div className="c-link__main p-contents__link-wrapper">
                <span className="p-contents__link-span">
                  <span className="c-link__main-inner">
                    私たちと共にCRISPで働く
                  </span>
                  <ArrowX />
                </span>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Careers
