import { Link } from "gatsby"
import React from "react"
import ArrowX from "../../common/ArrowX"
import NewsList from "../../common/NewsList"

const News = data => {
  return (
    <section className="p-common-news p-top-news">
      <div className="p-common-news__wrapper">
        <div className="p-common-news__header">
          <h2 className="c-ttl__en p-common-news__header-ttl">NEWS</h2>
        </div>
        <div className="p-common-news__body">
          {data.props.allContentfulNews.edges.map(edge => (
            <NewsList key={edge.node.slug} news={edge.node} />
          ))}
        </div>
        <div className="p-common-news__footer">
          <Link
            className="js-hover p-common-news__footer-link"
            data-hover-in-time="600"
            to="/news/"
          >
            <span className="c-link__main-inner p-common-news__footer-span">
              ALL
            </span>
            <ArrowX />
          </Link>
        </div>
      </div>
    </section>
  )
}

export default News
